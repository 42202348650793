import { Component, OnInit } from '@angular/core';
import { CommonModule } from "@angular/common";

@Component({
  selector: 'app-message-banner',
  templateUrl: './message-banner.component.html',
  standalone: true,
  imports: [CommonModule],
  styleUrl: './message-banner.component.scss'
})
export class MessageBannerComponent implements OnInit {

  private currentDate: Date = new Date();
  private targetDate: Date = new Date('2025-01-04');
  public showMessageBanner: boolean = this.currentDate < this.targetDate;

  constructor() { }

  ngOnInit(): void {
  }

}