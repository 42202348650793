import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RefreshListConfiguredRepairCentresService {
  private triggerRepairCentreRefreshSource = new Subject<void>();
  triggerRepairCentreRefresh$ = this.triggerRepairCentreRefreshSource.asObservable();

  notifyTriggerRepairCentreRefresh() {
    this.triggerRepairCentreRefreshSource.next();
  }
}
