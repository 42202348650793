<div class="modal-header border-bottom-0">
    <div class="document-title">
        <div class="document-icon" [innerHtml]="documentIcon"></div>
        <div class="document-details">
            <h4 class="text-black lh-sm mb-0">{{documentFilename}}</h4>
            <div class="lh-sm">
                <span class="d-inline-block fw-bold me-3">{{documentSize}}</span>
                <button class="btn btn-sm btn-link p-0" (click)="downloadDocument()">
                    <svg class="me-1" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                        <polyline points="7 10 12 15 17 10"></polyline>
                        <line x1="12" y1="15" x2="12" y2="3"></line>
                    </svg>Download
                </button>
            </div>
        </div>
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeDocumentModal()"></button>
</div>
<div class="modal-body py-0">
    <div class="ratio ratio-4x3 position-relative">
        <div class="d-flex justify-content-center align-items-center fade" [ngClass]="{'show': isLoading}">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div
            class="position-absolute top-0 end-0 bottom-0 start-0 overflow-auto zoom-container bg-gray-200 border rounded-3"
            style="transition: opacity 0.3s ease-in-out"
            [ngClass]="{'opacity-25': isLoading}"
        >
            <img *ngIf="documentImgUrl" class="w-100 overflow-scroll" [src]="documentImgUrl" [ngClass]="{'zoom': isZoomed}" alt="{{documentAltTag}}" (click)="toggleZoom()">
            <iframe *ngIf="documentIframeUrl" class="w-100 h-100" [src]="documentIframeUrl"></iframe>
        </div>
    </div>
</div>
<div class="modal-footer border-top-0">
    <div class="text-center w-100 my-0">
        <button class="btn btn-link py-0" ngbTooltip="Previous" (click)="viewPreviousDocument()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
        </button>
        <button class="btn btn-link py-0" ngbTooltip="Next" (click)="viewNextDocument()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
        </button>
    </div>
</div>
