
<div class="row mb-3">
    <div class="col-auto">
        <h1 class="h3 mb-2">
            Claim Limits
        </h1>
    </div>
</div>

<div class="col-12">
    <div class="card">
        <div class="card-header pb-0">
            <h2 class="card-title">Global</h2>
        </div>
        <div class="card-body">
            <div class="row">
                <fieldset class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                    <label class="form-label text-nowrap mb-0" for="globalDollarLimit">Max Amount Limit</label>
                    <div class="input-group has-validation">
                        <span class="input-group-text">$</span>
                        <input 
                            id="globalDollarLimit"
                            type="text" 
                            class="form-control" 
                            [ngClass]="{'is-invalid': !isGlobalDollarLimitValid}" 
                            [(ngModel)]="globalDollarLimit" 
                            (blur)="validateGlobalAmountLimit()"
                            required
                        >
                        <div class="invalid-feedback lh-sm">Please enter a valid amount.</div>
                    </div>
                </fieldset>
                <fieldset class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                    <label class="form-label text-nowrap mb-0" for="globalPercentageLimit">Max Percentage Limit</label>
                    <div class="input-group has-validation">
                        <input 
                            id="globalPercentageLimit"
                            type="text"
                            class="form-control"
                            [ngClass]="{'is-invalid': !isGlobalPercentageLimitValid}"
                            [(ngModel)]="globalPercentageLimit" 
                            (blur)="validateGlobalPercentageLimit()" 
                            required
                        >
                        <span class="input-group-text">%</span>
                        <div class="invalid-feedback lh-sm">Please enter a valid percentage.</div>
                    </div>
                </fieldset>
                <div class="col-sm-12 col-md-4 col-lg-3 col-xl-2 mt-label mb-3">
                    <button 
                        class="btn btn-primary px-4 pt-label" 
                        (click)="openGlobalConfirmModal()"
                        [disabled]="!(isGlobalDollarLimitValid && isGlobalPercentageLimitValid)"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save me-1"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-12">
    <div class="card">
        <div class="card-header pb-0">
            <div class="row">
                <div class="col-auto">
                    <h2 class="card-title">Dealer</h2>
                </div>
                <div class="col-auto ms-auto text-end">
                    <button class="btn btn-primary shadow-sm" (click)="searchVendor()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus me-1"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                        Add
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table id="vendorDataTable" #vendorDataTable datatable [dtOptions]="vendorDtOptions" class="table table-sm table-striped table-hover lh-md w-100"></table>
            </div>
        </div>
    </div>
</div>

<div class="col-12">
    <div class="card">
        <div class="card-header pb-0">
            <div class="row">
                <div class="col-auto">
                    <h2 class="card-title">Repair Center</h2>
                </div>
                <div class="col-auto ms-auto text-end">
                    <button class="btn btn-primary shadow-sm" (click)="searchRepairCentre()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus me-1"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                        Add
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table id="repairCentreDataTable" #repairCentreDataTable datatable [dtOptions]="repairCentreDtOptions" class="table table-sm table-striped table-hover lh-md w-100"></table>
            </div>
        </div>
    </div>
</div>
