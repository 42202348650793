<app-spinner [show]="loading" [center]="true"></app-spinner>

<ng-container *ngIf="!loading" class="d-flex justify-content-center">

  <div #statsBar>
    <app-stats [ngClass]="claimDto ? 'd-block' : 'd-none'" [claimDto]="claimDto"></app-stats>
  </div>

  <div #stickyHeader>
    <app-sticky-header
      *ngIf="claimDto"
      [claimDto]="claimDto"
      [repairTotal]="repairTotal"
      [busySubmitting]="busyUpdatingRepairCentre"
      [isLocked]="isLocked"
      (updateButtonClicked)="setUpdateForRepairCentre()">
    </app-sticky-header>
  </div>

  <h1 class="h3 mb-4"><a class="link-primary" href="/adjuster/claim-list">Claims List</a> > {{claimDto?.coverageTypeGroupLabel}}</h1>

  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-header bg-primary-dark text-light d-block d-md-flex">
          <app-vehicle-information
            [claim]="claimDto"
            class="d-block d-xl-flex">
          </app-vehicle-information>
          <div class="border-start border-white-35 d-none d-md-flex ps-md-4"></div>
          <app-warranty-information
            [warranty]="claimDto!.warranty"
            [IsAdjusterView]="true"
            class="pt-3 pt-1 pt-md-1">
          </app-warranty-information>
        </div>

        <div class="card-body bg-stat p-4">
          <div class="row">
            <div class="col-sm-6 col-lg-8 col-xl-9 px-3">
              <div class="row">
                <div class="col-12">
                  <app-duplicate-claims [claimId]="claimDto!.claimId"></app-duplicate-claims>
                </div>
                <div class="col-xl-5 mt-auto">
                  <div class="row">
                    <app-assign-to [claimDto]="claimDto" class="col-12 mb-3"></app-assign-to>
                    <app-claim-authorization-number [claimDto]="claimDto" class="col-6 col-sm-12 col-md-6 mb-3"></app-claim-authorization-number>
                    <app-status (statusUpdated)="statusUpdated()" [claimDto]="claimDto" class="col-6 col-sm-12 col-md-6 mb-3"></app-status>
                  </div>
                </div>
                <div *ngIf="claimDto?.warranty?.deductible !== null" class="col-sm-12 col-md-6 col-xl-3 mt-auto mb-3 mb-md-0 mb-sm-2">
                  <app-deductible [claimDto]="claimDto!"></app-deductible>
                </div>
                <div class="col-6 col-sm-12 col-md-6 col-xl-3 mt-auto mb-3">
                  <app-is-claim-test-data [ClaimDto]="claimDto!"></app-is-claim-test-data>
                  <div class="mt-2 mb-3 mb-sm-0">
                    <app-update-button
                      [repairTotal]="repairTotal"
                      [isLocked]="isLocked"
                      [busySubmitting]="busyUpdatingRepairCentre"
                      (updateButtonClicked)="setUpdateForRepairCentre()"
                    >
                    </app-update-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-3 mt-lg-n5 px-3">
                <div #priceTotals class="bg-light border border-3 border-white rounded px-4 pt-4 pb-3">
                  <app-totals [claimDto]="claimDto!" [manuallyEnteredDeductible]="claimDto!.manuallyEnteredDeductible" [repairTotal]="repairTotal"></app-totals>
                </div>
            </div>
          </div>
        </div>

        <div class="card-body p-4">
          <ng-container *ngIf="claimDto">

            <div class="row mt-3">
              <div class="col-xl-9 px-3">
                <app-internal-comments [claimDto]="claimDto"></app-internal-comments>
                <app-messages [statusUpdated]="statusUpdatedEvent" [toName]="claimDto.submittedBy.firstName + ' ' + claimDto.submittedBy.lastName" [refreshMessages]="refreshMessages" [isAdjusterView]="true" [claimDto]="claimDto"></app-messages>
              </div>
            </div>

            <app-customer-information
              [applicationId]="claimDto.applicationId"
              [customerOnFile]="claimDto.customerOnFile"
              [isAdjusterView]="true"
            >
            </app-customer-information>

            <app-repairs
              [IsLocked]="isLocked"
              [ClaimDto]="claimDto"
              [Submitted]="submitted"
              [IsAdjusterView]="true"
              (totalUpdated)="setRepairTotal($event)"
              (repairsUpdated)="updateRepairs($event)"
            >
            </app-repairs>

            <div class="row">
              <div class="col-xl-9">
                <app-file-upload [IsAdjusterView]="true" [ClaimDto]="claimDto"></app-file-upload>
                <app-documents [ClaimDto]="claimDto" [customerOnFile]="claimDto.customerOnFile"></app-documents>
                <hr class="mt-4 text-black">
                <div class="text-end">
                  <a class="btn btn-outline-secondary btn-lg me-3" href="/">Cancel</a>
                  <button
                    class="btn btn-lg btn-primary position-relative"
                    type="button"
                    (click)="setUpdateForRepairCentre()"
                    >
                    <span
                      class="spinner fade spinner-border spinner-border-sm position-absolute top-0 bottom-0 start-0 end-0 m-auto"
                      role="status" aria-hidden="true" [ngClass]="{'show': busyUpdatingRepairCentre}">
                    </span>
                    <span class="text fade" [ngClass]="{'show': !busyUpdatingRepairCentre}">Update Claim</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-9">
                <div class="bg-light rounded border my-4 p-3">
                  <span class="h4">Calculated Logic</span>
                  <button class="btn btn-link btn-collapse collapsed float-end p-0" type="button" data-bs-toggle="collapse" data-bs-target="#preApprovalExplanationExpandCollapse" aria-expanded="false" aria-controls="preApprovalExplanationExpandCollapse">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path></svg>
                  </button>
                  <pre id="preApprovalExplanationExpandCollapse" class="collapse mt-3 mb-0">{{claimDto.preApprovalExplanation}}</pre>
                </div>
              </div>
            </div>

          </ng-container>
        </div>

      </div>
    </div>
  </div>

</ng-container>
