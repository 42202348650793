import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SetGlobalConfigurationService {

  constructor(private httpClient: HttpClient) { }

  setGlobalConfiguration(globalDollarLimit: number | null, globalPercentageLimit: number | null) {
    return this.httpClient.put(`${environment.apiServer}/Adjuster/Configuration/SetGlobalConfiguration`, {
        globalDollarLimit,
        globalPercentageLimit
    });
  }
}
