import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GetAttachmentService {

  constructor(private httpClient: HttpClient) {
  }

  getAttachment(documentId: number) {
    return this.httpClient.get(`${environment.apiServer}/DownloadUploadedFile`, {
      responseType: 'blob',
      params: {documentId: documentId}
    });
  }
}
