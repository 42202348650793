import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, take } from 'rxjs/operators';

export const isClaimsManagerOrAssistantClaimsManager = () => {
  const oidcSecurityService = inject(OidcSecurityService);
  const router = inject(Router);

  return oidcSecurityService.getUserData().pipe(
    take(1),
    map((userData) => {
      if (!userData || !userData.Roles.includes('GlobalWarrantyAssistantClaimsManager') && !userData.Roles.includes('GlobalWarrantyClaimsManager')) {
        router.navigate(['']);
        return false;
      }
      return true;
    }),
  );
};
