<div *ngIf="noRepairCentreId">
  <app-unaffiliated-account></app-unaffiliated-account>
</div>

<app-message-banner></app-message-banner>

<app-message-notice></app-message-notice>

<div class="row mb-3">
  <div class="col-auto">
    <h1 class="h3 mt-3 mb-0">Your Claims</h1>
  </div>
  <div *ngIf="!noRepairCentreId" class="col-auto ms-auto text-end">
    <button (click)="openVinSearchModal()" class="btn btn-lg btn-primary shadow-lg">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather-plus me-1">
        <line x1="12" y1="5" x2="12" y2="19"></line>
        <line x1="5" y1="12" x2="19" y2="12"></line>
      </svg>
      Start a New Claim
    </button>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="table-responsive" *ngIf="!errorOccurred">
          <table id="claimsTable" #claimsDataTable datatable [dtOptions]="dtOptions" class="table table-striped table-hover w-100"></table>
        </div>

        <p *ngIf="errorOccurred">An error occurred while loading the Claims information.  Click here to retry.  <button (click)="errorOccurred = false;">Retry</button></p>

      </div>
    </div>
  </div>
</div>

