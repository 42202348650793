import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GetDocumentService {

  constructor(private httpClient: HttpClient) {
  }

  public getDocument(documentId: number) {
    return this.httpClient.get(`${environment.apiServer}/Adjuster/Claim/Files/DownloadDocument`, {
      responseType: 'blob',
      params: {documentId: documentId}
    });
  }
}
