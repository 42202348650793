import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-unaffiliated-account',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './unaffiliated-account.component.html',
  styleUrl: './unaffiliated-account.component.scss'
})
export class UnaffiliatedAccountComponent {
  constructor() { }

  ngOnInit() {

  }
}
