import { Component, OnInit } from '@angular/core';
import { CommonModule } from "@angular/common";

@Component({
  selector: 'app-message-notice',
  templateUrl: './message-notice.component.html',
  standalone: true,
  imports: [CommonModule],
  styleUrl: './message-notice.component.scss'
})
export class MessageNoticeComponent implements OnInit {
  public showNotice: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}