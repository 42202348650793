import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RefreshListConfiguredVendorsService {
  private triggerVendorRefreshSource = new Subject<void>();
  triggerVendorRefresh$ = this.triggerVendorRefreshSource.asObservable();
  
  notifyTriggerVendorRefresh() {
    this.triggerVendorRefreshSource.next();
  }
}
